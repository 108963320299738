import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/GlobalStyle';
import Theme from 'theme/mainTheme';
// BOUNDARY
import { ErrorBoundary } from 'react-error-boundary';
import ErrorAlert from 'boundary/ErrorAlert';
// COMPONENTS
import Header from 'components/organisms/Header/Header';
import Footer from 'components/organisms/Footer/Footer';
import BillboardsRandom from 'components/molecules/Billboards/BillboardsRandom';
import CookiesAlert from 'components/molecules/CookiesAlert/CookiesAlert';

const ContainerTemplate = ({ children }) => (
	<div className="App">
		<GlobalStyle />
		<ThemeProvider theme={Theme}>
			<>
				<CookiesAlert />
				<div className="AppContent">
					<ErrorBoundary FallbackComponent={ErrorAlert}>
						<BillboardsRandom />
					</ErrorBoundary>
					<Header />

					{children}
				</div>
				<Footer />
			</>
		</ThemeProvider>
	</div>
);

ContainerTemplate.propTypes = {
	children: PropTypes.element.isRequired,
};

export default ContainerTemplate;
