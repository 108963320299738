const initialState = {
	forms: {},
	galleries: {},
	articles: {},
	blogs: {},
	banners: null,
	rankings: {},
	winners: {},
};

const global = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_INITIAL_STATE_SUCESS':
			return action.payload;
		default:
			return state;
	}
};

export default global;
