import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

const StyledWrapper = styled.button`
	color: #fff;
	background-color: ${({ theme }) => theme.primary};

	:hover {
		color: #fff;
		background: ${({ theme }) => darken(0.075, theme.primary)};
	}

	:active,
	:focus {
		outline: none;
		box-shadow: ${({ theme }) => `0 0 0 0.2rem ${theme.primary}7f`};
	}

	${({ outline }) =>
		outline &&
		css`
			color: ${({ theme }) => theme.primary};
			background-color: #fff;
			border-color: ${({ theme }) => theme.primary};

			:hover {
				color: #fff;
				background-color: ${({ theme }) => theme.primary};
				border-color: ${({ theme }) => theme.primary};
			}
		`}

	${({ muted }) =>
		muted &&
		css`
			color: ${({ theme }) => theme.grey700};
			background-color: #fff;
			border-color: ${({ theme }) => theme.grey200};
			transition: none;

			:hover {
				color: ${({ theme }) => theme.grey700};
				background-color: #fff;
				border-color: ${({ theme }) => theme.grey400};
			}
		`}

	${({ active }) =>
		active &&
		css`
			color: #fff;
			background-color: ${({ theme }) => theme.primary};
			border-color: ${({ theme }) => theme.primary};

			:hover {
				color: #fff;
				background-color: ${({ theme }) => theme.primary};
				border-color: ${({ theme }) => theme.primary};
			}
		`}
`;

const Button = ({ children, className, type, onClick, disabled, muted, outline, active }) => (
	<StyledWrapper
		type={type}
		className={`btn ${className}`}
		onClick={onClick}
		outline={outline ? 1 : undefined}
		disabled={disabled ? 1 : undefined}
		muted={muted ? 1 : undefined}
		active={active ? 1 : undefined}
	>
		{children}
	</StyledWrapper>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	type: PropTypes.string,
	outline: PropTypes.bool,
	muted: PropTypes.bool,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

Button.defaultProps = {
	className: '',
	type: 'button',
	outline: false,
	muted: false,
	active: false,
	disabled: false,
	onClick: () => {},
};

export default Button;
