import React, { useState } from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// COMPONENTS
import Link from 'components/atoms/Link/Link';
import Button from 'components/atoms/Button/Button';

const CookiesAlertWrapper = styled.div`
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 999;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 0.5rem 1rem;
	background-color: #fff;
	border-top: 2px solid ${({ theme }) => theme.grey200};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: row;
	}
`;

const StyledParagraph = styled.p`
	margin: 0 1rem;

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-align: center;
		margin: 0.75rem 1rem;
	}
`;
const StyledIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.grey700};
`;

const CookiesAlert = () => {
	const [isCookies, setIsCookies] = useState(Cookies.get('perpsektywy_cookies'));
	const cookiesHandler = () => {
		Cookies.set('perpsektywy_cookies', '1', { expires: 60 });
		setIsCookies(true);
	};

	if (!isCookies) {
		return (
			<CookiesAlertWrapper>
				<StyledIcon icon={['fad', 'user-shield']} size="2x" />
				<StyledParagraph>
					W celu świadczenia usług na najwyższym poziomie informujemy, że do celów analitycznych wykorzystujemy
					pliki cookies. Dalsze korzystanie z portalu, bez zmiany ustawień przeglądarki internetowej oznacza, że
					użytkownik akceptuje stosowanie plików cookies.{' '}
					<Link
						featured
						href="http://perspektywy.pl/portal/index.php?option=com_content&amp;view=article&amp;id=16&amp;Itemid=123"
					>
						Polityka plików cookies.
					</Link>
				</StyledParagraph>
				<Button onClick={() => cookiesHandler()}>Rozumiem</Button>
			</CookiesAlertWrapper>
		);
	}
	return null;
};

export default CookiesAlert;
