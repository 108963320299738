import React, { useEffect } from 'react';
import styled from 'styled-components';
import useFetch from 'useHandlers/useFetch';
// REDUX
import { connect } from 'react-redux';
import { fetchCopyrightsSuccess } from 'redux/actions/fetch';
// COMPONENTS
import Link from 'components/atoms/Link/Link';
import UnorderedList from 'components/atoms/UnorderedList/UnorderedList';

const CopyrightsWrapper = styled.div`
	background-color: ${({ theme }) => theme.grey100};
	border-top: 1px solid ${({ theme }) => theme.grey300};
	font-size: 0.5rem;
	text-align: center;
	padding: 0.5rem 0;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 0.65rem;
	}

	${UnorderedList} {
		justify-content: center;
		margin-bottom: 0;
	}
`;

const CopyrightsTitle = styled.h6`
	font-size: 0.85rem;
	margin-bottom: 0;
`;

const Copyrights = ({ copyrights }) => {
	const endpoint = `${process.env.REACT_APP_API}/menu/pjjpdtxuc5`;
	const [{ error }, fetchInit] = useFetch({ endpoint, action: fetchCopyrightsSuccess });

	useEffect(() => {
		if (!copyrights) {
			fetchInit();
		}
	}, []);
	useEffect(() => {
		if (error) {
			// eslint-disable-next-line
			console.log(error);
		}
	}, [error]);

	return (
		<CopyrightsWrapper>
			<CopyrightsTitle>© 2023 Perspektywy.pl</CopyrightsTitle>
			{copyrights && (
				<UnorderedList horizontal>
					{copyrights.map(({ name, url, target }, index) => (
						<li key={index}>
							<Link href={url ?? ''} target={target ?? '_self'}>
								{name}
							</Link>
						</li>
					))}
				</UnorderedList>
			)}
		</CopyrightsWrapper>
	);
};

const mapStateToProps = ({ global }) => ({ copyrights: global.copyrights });

export default connect(mapStateToProps)(Copyrights);
