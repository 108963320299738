export const fetchInitialStateSuccess = (action) => ({
	type: 'FETCH_INITIAL_STATE_SUCESS',
	payload: action,
});
export const fetchArticleSuccess = (action) => ({
	type: 'FETCH_ARTICLE_SUCESS',
	payload: action,
});
export const fetchArticlesGroupSuccess = (action) => ({
	type: 'FETCH_ARTICLE_GROUP_SUCESS',
	payload: action,
});
export const fetchRankingSuccess = (action) => ({
	type: 'FETCH_RANKING_SUCESS',
	payload: action,
});
export const fetchBannersSuccess = (action) => ({
	type: 'FETCH_BANNERS_SUCESS',
	payload: action,
});
export const fetchMainMenuSuccess = (action) => ({
	type: 'FETCH_MAIN_MENU_SUCESS',
	payload: action,
});
export const fetchFooterSuccess = (action) => ({
	type: 'FETCH_FOOTER_SUCESS',
	payload: action,
});
export const fetchCopyrightsSuccess = (action) => ({
	type: 'FETCH_COPYRIGHTS_SUCESS',
	payload: action,
});
export const fetchGallerySuccess = (action) => ({
	type: 'FETCH_GALLERY_SUCESS',
	payload: action,
});

export default {
	fetchBannersSuccess,
	fetchGallerySuccess,
	fetchArticleSuccess,
	fetchArticlesGroupSuccess,
	fetchRankingSuccess,
	fetchInitialStateSuccess,
	fetchMainMenuSuccess,
	fetchFooterSuccess,
	fetchCopyrightsSuccess,
};
